#root {
    height: 100vh;
}

.body {
    flex: 1;
    width: 80%;
    align-self: center;
}

.App {
    text-align: center;
}

.header-left {
    flex: 1;
    justify-content: flex-start;
}

.header-logo {
    display: flex;
    pointer-events: none;
    height: 50px;
}

.header-center {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
}

.header-right {
    display: flex;
    flex: 1;
    justify-content: flex-end;
}

.header-user-email {
    font-size: 1.3rem;
    align-self: center;
    margin-right: 8px;
}

.header {
    width: 80%;
    margin: auto;
    background-color: white;
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}

.header-dots-icon {
    align-self: flex-end;
    color: black;
}

.header-bell-icon {
    align-self: center;
    margin-right: 8px;
}

@media (max-width: 1700px) {
    .header-user-email {
        display: none !important;
    }
}

.imp-iframe {
    border: none;
}

.footer {
    width: 100%;
    min-height: 30px;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 25px;
    color: rgb(87, 87, 87);
    display: flex;
}

.settings-form {
    width: 100%;
}

.settings-form form {
    width: 100%;
    margin-top: 40px;
}

.settings-form .submit-button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
}
